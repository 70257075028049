.sai_product_overview {   
    @apply lg:pt-[60px] pt-0;

    .sticky-link-a {
        opacity: 0.4;
    }

    .active-sticky {
        opacity: 1 !important;

    }

    .mobile-dropdown {
        @apply pt-[20px] mt-[40px] border-t border-b-0 border-r-0 border-l-0 border-solid border-[#DEDFE0];
    }

    .dropdown-sticky {
        @apply lg:top-[100px] z-[99] top-[60px] lg:py-0 py-[15px] lg:w-full w-[96%] lg:max-w-[268px];

        .dropdownDefaultButton {            
            @apply border-0 rounded-[12px] flex justify-between items-center w-full p-[15px];

            &.open {                
                @apply rounded-bl-none rounded-br-none;
            }
        }

        .disabled {
            @apply min-w-0 mr-0 w-0 h-0;
        }

        &__list {
            @apply bg-[#F3F9FE] rounded-[12px] p-[20px] flex flex-col gap-[20px] mt-0;

            li a {
                @apply text-[#25282E] hover:text-[#0074E8] hover:underline font-bold text-[20px];

                &:hover span {
                    @apply text-[#0074E8];
                }
            }

            .dropdownList {
                @apply rounded-bl-[12px] rounded-br-[12px];

                .nav-link {
                    @apply w-full px-[20px] py-[10px] text-left;                
                }
                
                .nav-item {
                    @apply w-full py-[10px] px-[15px] pl-[0px];
                }
            }
        }
    }

    .dropdownList {
        @apply rounded-bl-[12px] rounded-br-[12px] mt-[-14px];
        
        .nav-link {
            @apply w-full block px-[10px] py-0 text-left;                
        }
        
        .nav-item {
            @apply w-full py-[10px] px-[15px];
        }
    }

    .block-solutions {
        &__entry {
            @apply border-b border-r-0 border-l-0 border-t-0 border-solid border-[#DEDFE0] lg:py-[80px] py-[40px];
            
            &.first {
                @apply lg:pt-[26px] lg:pb-[80px] py-[40px];
            }
        }

        &__left {
            @apply flex lg:flex-row flex-col justify-between lg:items-center items-start lg:mb-[50px] mb-[20px] lg:gap-0 gap-[20px];
        }

        &__right {
            @apply flex lg:flex-row flex-col lg:gap-[40px] gap-[32px] lg:pb-[40px] pb-[20px] lg:mb-[30px] mb-[20px] border-b border-r-0 border-l-0 border-t-0 border-dashed border-[#0074E8];

            .subtitle {
                @apply lg:mb-[32px] mb-[20px];
            }

            .text-paragraph {
                @apply lg:mb-[30px];
            }
        }

        &__features {
            @apply grid;

            &.two-col {
                @apply lg:grid-cols-2;

                .block-solutions__features-list a:hover {
                    @apply lg:w-[90%];
                }
            }

            &-list {
                @apply flex flex-row items-center;

                svg {
                    @apply hidden;
                }

                a {
                    @apply relative flex items-center justify-start hover:justify-between gap-[15px] text-[#0074E8] w-full py-[10px];

                    &:hover {
                        @apply cursor-pointer lg:w-[90%] w-[95%] lg:ml-0 ml-[10px];
    
                        &::before {
                            @apply content-[''] bg-[#F3F9FE] rounded-[12px] w-[106%] absolute left-[-3%] h-[106%] top-[-3%] z-[-1];                                                    
                        }
    
                        svg {
                            @apply block;
                        }
                    }
                }                               
            }
        }
    }
    
    .block-img {
        @apply relative;

        .stats {
            @apply absolute bottom-0 z-[1] w-full;

            img {
                @apply w-full object-contain block h-auto;
            }
        }
    }

    .block-solutions__right {
        border: none !important;
        margin-bottom: 0px !important;

    }
        @media (min-width: 768px) {
        .block-solutions__bottom {
            padding-left: 40px;
            padding-right: 40px;
        }
        }
    
    @media (min-width: 1024px) {
        .block-solutions__right {
            padding-left: 40px;
        }
    }
    .block-solutions_new {
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;
        padding-bottom: 0;
}
.block-solutions__entry  {
--tw-shadow: 0 0 10px 0px rgba(0, 0, 0, .2);
    --tw-shadow-colored: 0 0 10px 0px var(--tw-shadow-color);
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    margin: 1px;
    position: relative;
    background: linear-gradient(107deg, #ffffff 9.26%, #FCFDFE 117.15%);
    border-color: #fdfdfe;
}

.new-product-p{
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    padding-bottom: 0;
}
.new-product-tile {
    --tw-shadow: 0 0 10px 0px rgba(0, 0, 0, .2);
    --tw-shadow-colored: 0 0 10px 0px var(--tw-shadow-color);
    border-radius: 12px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    margin: 1px;
    position: relative;
    background: linear-gradient(107deg, #ffffff 9.26%, #ffffff 117.15%);
    min-height: 194px;
    border-color: #fdfdfe;

}
.resource-1 {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #0074e8 10.36%, #a933fb 61.75%);
            opacity: 1;
        }
    }
}

.resource-2 {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #ff8500 10.36%, #ffb600 61.75%);
            opacity: 1;
        }
    }
}

.resource-3 {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #a933fb 10.36%, #0074e8 61.75%);
            opacity: 1;
        }
    }
}

.resource-4 {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #fb24a9 10.36%, #FFB600 61.75%);
            opacity: 1;
        }
    }
}

.resource-5 {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #0184E2 10.36%, #1fcfc5 61.75%);
            opacity: 1;
        }
    }
}

.resource-retail {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg,#1fcfc5 10.36%,#0076f1 61.75%);
            opacity: 1;
        }
    }
}

.resource-6 {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #008afc 10.36%, #0076f1 61.75%);
            opacity: 1;
        }
    }
}
.resource-orange {
    .new-product-tile {
        &:hover::after {
            background: linear-gradient(119deg, #ffb600 10.36%, #FF8500 61.75%);
            opacity: 1;
        }
    }
}
}
a .new-product-tile {
    &::before {
        content: "";
    }

    .content-wrapper {
        @apply p-[20px];
    }

    &::after {
        content: "";
        border-radius: 439px;
        bottom: -60px;
        filter: blur(33.85px);
        position: absolute;
        width: 100%;
        height: 60px;
        background: transparent;
        opacity: 0;
        transition: opacity 0.4s ease;
    }

}

.bln-p {
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 90%;
        border-radius: inherit;
        filter: blur(20px);
        opacity: 0.27;
        z-index: -1;
        bottom: -28px;
    }
    
}

.bln-p-left {
    &::before {
        left: -25px;
    }
    
}

.bln-p-right {
    &::before {
        right: -25px;
    }   
}

.product-p-1 {
    &::before {
        background: linear-gradient(119deg, #0074e8 10.36%, #a933fb 61.75%);
    }
}

.product-p-2 {
    &::before {
        background: linear-gradient(119deg, #ff8500 10.36%, #ffb600 61.75%);
    }
}

.product-p-3 {
    &::before {
        background: linear-gradient(119deg, #BF77F6 10.36%, #0076f1 61.75%);
}
}

.product-p-4 {
    &::before {
        background: linear-gradient(119deg, #fb24a9 10.36%, #d29d12 61.75%);
}
}

.product-p-5 {
    &::before {
        background: linear-gradient(119deg, #1fcfc5 10.36%, #0184E2 61.75%);
    }
}

.product-p-retail {
    &::before {
        background: linear-gradient(119deg,#0076f1 10.36%,#1fcfc5 61.75%);
    }
}

.product-p-6 {
    &::before {
        background: linear-gradient(119deg, #008afc 10.36%, #0076f1 61.75%);
}
}
.product-p-orange {
    &::before {
        background: linear-gradient(119deg, #ffb600 10.36%, #ffb600 61.75%);
}
}


.block-solutions__bottom a {
    color: #000;
}

.new-product-tile h4 {
    min-height: 48px;
}