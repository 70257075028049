.block_sai_accordion {
    @apply overflow-hidden;

    @media (min-width: 1024px) {
        .faq {
            min-height: 630px;
        }
    }

    .text-paragraph-large {
        p {
            @apply dark:text-sol-white;
        }
    }
	
	.bg-dark {
		.heading {
			@apply text-sol-white;
		}
		.text-paragraph-large {
			@apply text-sol-white;
		}
	}

    .asset-item {
        @apply h-full relative overflow-hidden md:rounded-[16px] rounded-[8px] ease-in-out duration-200;

        @media(max-width:767px) {
            @apply mt-[32px];
        }

        @media(max-width:576px) {
            @apply max-h-[380px] mt-[20px];
        }

        .image-container,
        .video-container {
            @apply w-full h-full object-cover absolute top-0 left-0 z-0;

            &.\!object-contain {
                @apply object-contain;
            }
        }

        .stats-card {
            background-color: rgba($color: #fff, $alpha: 0.6);
            background-image: url(var(--base-url) + "bg-card-stats.png");
            @apply absolute bottom-[20px] left-[20px] right-[20px] z-[1] shadow-[0px_5px_16px_0px_#0000001A] md:rounded-[12px] rounded-[6px] flex flex-col md:gap-[16px] gap-[8px] bg-right-bottom bg-contain bg-no-repeat w-[calc(100%-40px)] md:max-w-[280px] phablet:max-w-[220px] max-w-[160px] overflow-hidden backdrop-blur-[10px];


            @media(max-width:767px) {
                @apply p-[20px_20px_40px];
            }

            .stats-label {
                @apply text-[#656C78] md:text-[18px] phablet:text-[16px] text-[14px] leading-[20px] font-semibold;
            }

            .stats-value {
                @apply text-black md:text-[52px] phablet:text-[44px] text-[28px] leading-[1] font-semibold;
            }

            .stats-variation {
                @apply text-[#008933] md:text-[24px] phablet:text-[14px] text-[10px] leading-[30px] font-semibold flex items-center;

                i {
                    @apply md:w-[32px] phablet:w-[24px] w-[16px] md:h-[32px] phablet:h-[24px] h-[16px] bg-contain;
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.9251 15.0496C24.9754 14.9282 25.0011 14.798 25.0004 14.6667C25.0011 14.5353 24.9754 14.4051 24.9251 14.2837C24.8747 14.1624 24.8006 14.0523 24.7071 13.96L16.7245 5.97734C16.7188 5.97134 16.713 5.96541 16.7071 5.95954C16.5196 5.772 16.2652 5.66665 16 5.66665C15.7348 5.66665 15.4804 5.772 15.2929 5.95954C15.2826 5.96979 15.2726 5.98024 15.2629 5.99087L7.29378 13.96C7.11714 14.1496 7.02097 14.4003 7.02555 14.6593C7.03012 14.9184 7.13507 15.1656 7.31828 15.3488C7.5015 15.532 7.74868 15.637 8.00775 15.6416C8.26682 15.6461 8.51755 15.55 8.70711 15.3733L15 9.08043L15 25.3333C15 25.5985 15.1054 25.8529 15.2929 26.0404C15.4804 26.228 15.7348 26.3333 16 26.3333C16.2652 26.3333 16.5196 26.228 16.7071 26.0404C16.8946 25.8529 17 25.5985 17 25.3333L17 9.07954L23.2938 15.3733C23.4813 15.5606 23.7354 15.6658 24.0004 15.6658C24.2654 15.6658 24.5196 15.5606 24.7071 15.3733C24.8006 15.281 24.8747 15.1709 24.9251 15.0496Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
                }
            }

            &.stat-card-2 {
                background-image: url("https://symphonyai.com/wp-content/uploads/2023/09/Financial_Crime_Prevention_graph.svg");
                background-size: auto;
            }

            &.stat-card-3 {
                background-image: url("https://symphonyai.com/wp-content/uploads/2023/09/Industrial_graph.svg");
                background-size: auto;
            }

            &.stat-card-4 {
                background-image: url("https://symphonyai.com/wp-content/uploads/2023/09/Media_graph.svg");
                background-size: auto;
            }

            &.stat-card-5 {
                background-image: url("https://symphonyai.com/wp-content/uploads/2023/09/Enterprise_Service_Management_graph.svg");
                background-size: auto;
            }
        }
    }

    .asset-group {
        @apply md:pr-[48px] overflow-hidden pr-0 md:h-full h-[400px] md:mb-0 mb-[40px];
    }

    .accordion-header-main {
        @apply mb-0 pb-[20px];
    }

    .accordion {
        @apply rounded-none border-none;

        .accordion-item {
            @apply rounded-none border-none relative duration-200 ease-in-out;            
            border-top: 1px solid;

            &.border-green {
                border-image: linear-gradient(90deg,transparent, #27e9c3, transparent) 1 0 1 0;
            }

            &.border-blue {
                border-image: linear-gradient(90deg,transparent, #1EACFC, transparent) 1 0 1 0;
            }

            &.border-blue {
                border-image: linear-gradient(90deg,transparent, #0074e8, transparent) 1 0 1 0;
            }

            &.border-yellow {
                border-image: linear-gradient(90deg,transparent, #ffb600, transparent) 1 0 1 0;
            }

            &.border-purple {
                border-image: linear-gradient(90deg,transparent, #a933fb, transparent) 1 0 1 0;
            }
            
            &.border-pink {
                border-image: linear-gradient(90deg,transparent, #fb24a9, transparent) 1 0 1 0;
            }

            &:last-child {
                border-bottom: 1px solid;
            }
            .accordion-button {
                @apply cursor-pointer text-[#25282E] dark:text-sol-white desktop:text-[18px] md:text-[18px] text-[16px] desktop:leading-[22px] md:leading-[22px] leading-[20px] font-semibold bg-transparent p-[20px_0px_32px] appearance-none relative md:border-t-0 md:border-solid md:border-[#25282E20] border-t-0 dark:border-sol-white rounded-none transition-all duration-200 ease-in-out shadow-none w-full text-left outline-none border-x-0 border-b-0 pb-0
                        before:content-[''] before:absolute before:top-0 before:left-0 before:w-0 before:bg-sol-green before:duration-[8s,0.01s]
                        after:content-[''] after:absolute after:top-[55%] after:right-0 after:w-[16px] after:h-[16px] after:bg-contain after:dark:brightness-100;

                &::before {
					display:none;
                    transition: width 8s ease-in-out, #00e8c4 .01s ease;
                }

                &[aria-expanded="true"] {
                    @apply opacity-100 before:w-full before:h-[4px] after:opacity-100;
                }       
            }
			
			iframe {
			    height: auto;
				width: 100%;
				aspect-ratio: 16 / 9;
			}

            .accordion-body {
                @apply min-[993px]:p-[0_0_20px] p-0;

                .accordion-description p {
                    @apply dark:text-sol-white;
                    font-size: 1rem;
                }

                .btn-link {
                    @apply text-[18px] p-[16px_0];
                }

                .btn-chevron span {
                    @apply after:right-[-18px] after:top-[5px] after:w-[9px] after:h-[13px] #{!important};
                }

                .asset-item {
                    @apply min-[993px]:hidden max-h-[500px];
                }
            }
        }
    }

    .collapse {
        visibility: visible;
    }

    &.bg-dark {
        .accordion-button {
            &::after {
                background-image: $IconXWhite;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.collapsed {
                @apply text-sol-white opacity-100;
                
                &::after {
                    background-image: $IconPlusWhite;
                }
            }

            &.open {
                @apply before:transition-none text-sol-white;

                &.collapsed {
                    @apply dark:text-sol-white opacity-100;
                    
                    &::after {
                        background-image: $IconPlusWhite;
                    }
                }
                
                &::after {
                    background-image: $IconXWhite;
                }
            }

            &:hover,
            &:focus,
            &:active {
                @apply outline-none shadow-none;
            }

            &:hover {
                @apply opacity-100 text-sol-white;
            }
        }

        .accordion-collapse.collapse {
            @apply hidden;
    
            &.show {
                @apply block;
            }
        }
    }

    &.bg-white-2 {
        .accordion-button {
            padding-right: 40px !important;

            &::after {
                background-image: $IconXDark;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.collapsed {
                @apply text-sol-charcoal opacity-100;
                
                &::after {
                    background-image: $IconPlusDark;
                }
            }

            &.open {
                @apply before:transition-none text-sol-charcoal;

                &.collapsed {
                    @apply text-sol-charcoal opacity-100;
                    
                    &::after {
                        background-image: $IconPlusDark;
                    }
                }
                
                &::after {
                    background-image: $IconXDark;
                }
            }

            &:hover,
            &:focus,
            &:active {
                @apply outline-none shadow-none;
            }

            &:hover {
                @apply opacity-100 text-sol-charcoal;
            }
        }

        .accordion-content {
            @apply overflow-hidden duration-500 ease-out max-h-0 opacity-0;
            transition: none;
    
            .text-dark {
                color: #25282e;
            }
        }
        
        .accordion-content.open {
            @apply max-h-[500px] opacity-100 duration-500 ease-in;
            transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
        }
    }
}

.blog-accordion .accordion-description {
    color: var(--charcoal, #25282E);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    flex: 1 0 0;
}

.blog-accordion .accordion-button {
    color: var(--charcoal, #25282E);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.36px;
}

@media (max-width: 767px) {

.blog-accordion .accordion-description  {
  padding-bottom: 15px; 
}

}
.blog-accordion h4.heading {
    color: var(--charcoal, #25282E);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}
.blog-accordion .text-paragraph-large {
    margin-bottom: 28px;
}

.blog-accordion {
    margin-top: 48px;
}