.block_SAI-3-Blocks {
    .variant-1.block-card {
        @apply gap-[2.5rem];

        .card-item {
            background-position: 50%;
            @apply min-h-[450px] bg-cover;
        }

        .card-item::before {
            @apply content-[''] opacity-[0.32] absolute p-[1px] rounded-[1rem] inset-0;
            -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
            mask-composite: exclude;            
        }

        .card-image {
            @apply w-[85%] mx-auto pt-[1rem] mb-[-200px];            
        }

        .card-body {
            padding: 40px 40px 20px;

            p {
                @apply leading-[22.4px] text-[16px];
            }
        }

        .card-item {
            background-size: cover;
            background-position: center;
            min-height: 450px;
        }

        .card-overlay {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 100%);
            opacity: 0.8;
            transition: opacity 0.4s ease-in-out;
        }

        .card-content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
        }
        
        .card-item:hover .card-overlay {
            opacity: 1;
        }
        
        .card-item:hover .card-content {
            max-height: 180px;
            opacity: 1;
        }
    }

    .variant-2.block-card {
        gap: 1.3rem;

        .card-item {
            background-size: cover;
            background-position: center;
            min-height: 432px;
        }

        .card-body {
            padding: 40px 40px 20px;

            p {
                @apply text-[14px] leading-[19.6px];
            }
        }

        .card-overlay {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
            opacity: 0.8;
            transition: opacity 0.4s ease-in-out;
        }

        .card-content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
        }
        
        .card-item:hover .card-overlay {
            opacity: 1;
        }
        
        .card-item:hover .card-content {
            max-height: 180px;
            opacity: 1;
        }
    }

    .btn.btn-primary {
        display: inline-block;
    }
}
